import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
	return (
		<footer>
			<div className='container'>
				<div className='row align-items-center'>
					<div className='col-lg-4 justify-content-between align-items-center d-flex'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/iai+logo+(1).png'
							alt=''
							className='img-fluid'
						/>
						<div>
							<a
								className='text-decoration-none text-dark'
								target='_blank'
								href='https://maps.app.goo.gl/S4tgSiR6KHhvLsB79'
								rel='noreferrer'
							>
								<p className='mb-0'>
									ELITE COLOSSAL, <br /> FC ROAD. PUNE <br /> info@ingnious.ai
								</p>
							</a>
						</div>
					</div>
					<div className='col-lg-7 footer-links offset-lg-1'>
						<div className='row'>
							<div className='col-lg-4'>
								<Link
									className='text-decoration-none text-dark'
									to='/about#about'
								>
									<p>ABOUT US</p>
								</Link>
								<Link
									className='text-decoration-none text-dark'
									to='/about#careers'
								>
									<p>CAREERS</p>
								</Link>
								<Link
									className='text-decoration-none text-dark'
									to='/about#contact'
								>
									<p>CONTACT US</p>
								</Link>
							</div>
							<hr className='d-lg-none' />
							<div className='col-lg-4'>
								<Link
									className='text-decoration-none text-dark'
									to='/'
								>
									<p>HOME</p>
								</Link>
								<Link
									className='text-decoration-none text-dark'
									to='/fashion'
								>
									<p>FASHION SOLUTIONS</p>
								</Link>
								<Link
									className='text-decoration-none text-dark'
									to='/services'
								>
									<p>SERVICE SOLUTIONS </p>
								</Link>
							</div>
							<hr className='d-lg-none' />
							<div className='col-lg-4'>
								<Link
									className='text-decoration-none text-dark'
									to='/#patented-tech'
								>
									<p>PATENT TECHNOLOGY</p>
								</Link>

								<p>PRESS COVERAGE </p>
								<p>OUR CLIENTS </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
