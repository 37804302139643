import React, { useState } from 'react'
import NavBar from '../Components/NavBar'
import { Icon } from '@iconify/react'
import '../Css/About.css'
import axios from 'axios'
import Footer from '../Components/Footer'
const AboutUs = () => {
	const [name, setName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')

	const sendQuery = async (e) => {
		e.preventDefault()
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			}
			const { data } = await axios.post(
				`https://iqpn5giiei.execute-api.ap-south-1.amazonaws.com/default/website-query-emailer`,
				{ name, email, phoneNumber, message },
				config
			)
			if (data) {
				window.alert('Thank you for contacting')
			}
		} catch (error) {
			window.alert(error)
		}
	}

	return (
		<div>
			<div>
				<NavBar active={3} />
			</div>
			<div
				id='about'
				className='pt-lg-5 pt-4 about pb-lg-5 position-relative'
				style={{}}
			>
				<img
					src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/website/arrow+(1).png'
					alt=''
					className='arrow-img'
				/>

				<div className='position-relative container'>
					<div
						style={{ zIndex: '1' }}
						className=' about-section-1 '
					>
						<h2 className=' text-center    ps-3 pe-3  '>About Us</h2>{' '}
						<p
							style={{ zIndex: '1' }}
							className=' mx-auto  mt-lg-2'
						>
							After years of dedicated research, the visionary founder of
							InGnious AI, Nachiket Kulkarni, achieved a groundbreaking leap in
							technology, igniting the journey of InGnious. With a passionate AI
							team by his side, they crafted a revolutionary solution to
							eliminate all hindrances, paving the way for scalability and
							affordability. We firmly believe that this cutting-edge technology
							is a giant leap forward, poised to empower every e-commerce and
							retail enterprises to deliver unparalleled experiences to their
							customers. It's the epitome of ingenuity in shopping—a seamless
							blend of innovation and intuition, guiding shoppers toward the
							perfect decisions.
						</p>
					</div>
					<img
						src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/about+us.png'
						alt=''
						className='img-fluid d-none pt-4 d-lg-block about-main-img'
					/>
				</div>

				<section
					className='container form-section '
					id='contact'
					style={{}}
				>
					<div className='row align-items-center'>
						<div className='col-lg-6 p-3'>
							<h2
								className=' text-center    ps-3 pe-3  '
								style={{
									color: '#FBA885',
									fontWeight: '700',
									fontSize: '2em',
								}}
							>
								Contact Us
							</h2>{' '}
							<div>
								<p>Say hi to the team</p>
								<p>
									Feel free to contact us and we will get back to you as soon as
									we can{' '}
								</p>
								<form
									className='mt-3'
									onSubmit={sendQuery}
								>
									<input
										type='text'
										style={{ border: '1px solid #FBA885' }}
										placeholder='Name'
										value={name}
										onChange={(e) => setName(e.target.value)}
										className='w-100 rounded-2 p-2 mb-4'
									/>
									<input
										type='number'
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
										style={{ border: '1px solid #FBA885' }}
										placeholder='Phone no '
										className='w-100 rounded-2 p-2 mb-4 '
									/>
									<input
										type='email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										style={{ border: '1px solid #FBA885' }}
										placeholder='Email'
										className='w-100 rounded-2 p-2 mb-4'
									/>
									<textarea
										name=''
										value={message}
										onChange={(e) => setMessage(e.target.value)}
										style={{ border: '1px solid #FBA885' }}
										className='w-100 p-3 rounded-2'
										placeholder='Type your message here '
										id=''
										rows='5'
									></textarea>
									<button
										type='submit'
										style={{
											fontSize: '1.3em',
											color: '#fff',
											background: 'rgb(251,168,133)',
											background:
												'linear-gradient(164deg, rgba(251,168,133,1) 50%, rgba(224,141,150,1) 100%)',
										}}
										className='btn mt-4 d-block w-100  '
									>
										Submit
									</button>
								</form>
							</div>
						</div>
						<div className='col-lg-6 p-4'>
							<a
								className='text-decoration-none text-dark'
								target='_blank'
								href='https://maps.app.goo.gl/S4tgSiR6KHhvLsB79'
								rel='noreferrer'
							>
								<div className='d-flex align-items-center'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/website/location+2+1.png'
											alt=''
											className='img-fluid d-block mx-auto'
										/>
									</div>
									<div>
										<p>InGnious AI Elite Collosal FC Road , Pune</p>
										<p className='d-flex align-items-center '>
											<Icon
												className='me-lg-3 me-2'
												style={{ color: '#FBA885' }}
												icon='fluent:mail-48-regular'
												width={25}
												height={25}
											/>
											info@ingnious.ai
										</p>
									</div>
								</div>
							</a>
						</div>
					</div>
				</section>

				<div
					style={{ zIndex: '1' }}
					id='careers'
					className='mt-5 container careers position-relative'
				>
					<h2
						className=' text-center    ps-3 pe-3  '
						style={{
							color: '#FBA885',
							fontWeight: '700',
							fontSize: '2em',
						}}
					>
						Careers
					</h2>{' '}
					<p className=' p-2 mx-auto mt-lg-5'>
						Join us as we grow , inspire , innovate and empower together and
						build a better tomorrow. Drop in your CVs at info@ingnious.ai and we
						will get back to you at the earliest
					</p>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default AboutUs
