import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomeScreen from './Screens/HomeScreen'
import AboutUs from './Screens/AboutUs'
import Services from './Screens/Services'
import Fashion from './Screens/Fashion'
function App() {
	return (
		<Router>
			<Routes>
				<Route
					index
					path='/'
					element={<HomeScreen />}
				/>
				<Route
					path='/about'
					element={<AboutUs />}
				/>
				<Route
					path='/services'
					element={<Services />}
				/>
				<Route
					path='/fashion'
					element={<Fashion />}
				/>
			</Routes>
		</Router>
	)
}

export default App
