import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useLocation } from 'react-router-dom'
const NavBar = ({ active }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'auto',
		})
	}, [])
	const location = useLocation()

	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.substring(1))
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [location])
	return (
		<>
			<div
				className='pt-2 pb-2 d-none d-lg-block  position-relative '
				style={{ zIndex: '99' }}
			>
				<div className='container  align-items-center d-flex justify-content-between'>
					<div className=''>
						<Link
							to={'/'}
							className='text-decoration-none text-dark '
						>
							<img
								src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/logo+(1).png'
								className='img-fluid'
								style={{ width: '200px' }}
								alt=''
							/>
						</Link>
					</div>
					<div
						className='d-flex '
						style={{ gap: '2em', fontWeight: '900' }}
					>
						<Link
							to={'/fashion'}
							className={`text-decoration-none  ${
								active === 1 ? 'nav-active-link' : 'text-dark'
							} `}
						>
							<p className='mb-0'>FASHION</p>
						</Link>{' '}
						<Link
							to={'/services'}
							className={`text-decoration-none  ${
								active === 2 ? 'nav-active-link' : 'text-dark'
							} `}
						>
							<p className='mb-0'>SERVICES</p>
						</Link>{' '}
						<Link
							to={'/about'}
							className={`text-decoration-none  ${
								active === 3 ? 'nav-active-link' : 'text-dark'
							} `}
						>
							<p className='mb0'>ABOUT US</p>
						</Link>
					</div>
				</div>
			</div>
			<div className='d-flex ps-2 pe-3 justify-content-between  d-lg-none align-items-center'>
				<div className=' p-2'>
					<Link
						to={'/'}
						className='text-decoration-none text-dark '
					>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/logo+(1).png'
							className='img-fluid'
							style={{ width: '150px' }}
							alt=''
						/>
					</Link>
				</div>
				<div>
					<div class='dropdown'>
						<Icon
							className='me-lg-3 me-2'
							data-bs-toggle='dropdown'
							style={{ color: '#000' }}
							icon='heroicons-solid:menu-alt-3'
							width={35}
							height={35}
						/>
						<ul
							class='dropdown-menu'
							aria-labelledby='dropdownMenuButton1'
						>
							<li className='mt-2'>
								<Link
									class='dropdown-item'
									to={'/fashion'}
								>
									FASHION
								</Link>
							</li>
							<li className='mt-2'>
								<Link
									class='dropdown-item'
									to={'/services'}
								>
									SERVICES
								</Link>
							</li>
							<li className='mt-2'>
								<Link
									class='dropdown-item'
									to={'/about'}
								>
									ABOUT US
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default NavBar
