import React from 'react'
import NavBar from '../Components/NavBar'
import 'swiper/swiper-bundle.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import '../Css/homeScreen.css'
import '../Css/mob-homeScreen.css'
import Footer from '../Components/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
const HomeScreen = () => {
	return (
		<div>
			<div className='position-relative overflow-hidden home-section-1  pb-lg-5'>
				<NavBar color={true} />

				<img
					src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/header+for+home+page.png'
					alt=''
					className=' img-fluid home-section-1-bg-image'
				/>

				<div className='container'>
					<div className='row  align-items-lg-center'>
						<div className='col-lg-4 p-0 mt-lg-4   position-relative'>
							<div className=' mobile-overlay d-lg-none'></div>
							<img
								src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/ezgif.com-optimize.gif'
								className='img-fluid  d-block mx-auto  section-1-banner'
								alt=''
							/>
							<div className='mob-section-1-text d-lg-none'>
								<h2 className='text-center  '>
									TRANSFORMING <br /> FASHION'S FUTURE
								</h2>
								<p>
									B2B AI Tech Firm Introducing - AI Powered 3D Virtual Fitting
									Room
								</p>
							</div>

							<a
								href='https://youtu.be/tzzevnmCqww'
								className='btn  mob-section-1-btn d-lg-none  btn-outline-light'
							>
								VIEW DEMO
							</a>
						</div>
						<div className='col-lg-7 d-none d-lg-block offset-1 section-1-text'>
							<h2 className='text-center'>
								TRANSFORMING FASHION'S <br /> FUTURE
							</h2>

							<p className=' mx-auto mt-4'>
								We are a B2B AI tech firm expanding into the future of fashion
								with our AI driven 3D virtual trial rooms – blending innovation
								seamlessly with convenience, where style meets technology for an
								unparalleled personal fun shopping experience.
							</p>

							<a
								href='https://youtu.be/tzzevnmCqww'
								className='btn d-block mt-lg-5  view-demo mx-auto mb-5 btn-outline-light'
							>
								VIEW DEMO
							</a>
						</div>
					</div>
				</div>
			</div>
			{/* ...................... */}
			<div className='container mt-lg-5 mt-4 home-section-2'>
				<h2 className=' '>VIRTUFIT SOLUTIONS</h2>
				<div className='row align-items-center'>
					<div className='col-lg-6 d-none d-lg-block p-4'>
						<div className='mt-4 home-section-2-desc'>
							<p style={{ fontSize: '0.99em' }}>
								Online shopping lacks the experiential aspect of trying on
								clothes, often leading to dissatisfaction and financial losses
								for companies due to returns. Our "Virtual Fitting Room" solves
								this by letting customers visualize themselves in garments,
								enhancing their shopping experience and reducing return rates.
								This scalable, patented technology enables e- commerce platforms
								to offer state-of-the-art shopping experiences affordably
							</p>
							{/* <p
								className='mt-5'
								style={{ fontSize: '0.99em' }}
							>
								And so our Virtual try on called "Virtual Fitting Room" is a
								perfect solution to see yourself wearing that garment and create
								a beautiful experience for yourself and also see how it fits
								them. Moreover it is a patented technology which is scalable and
								affordable, making it possible for e commerce businesses to host
								state of the art shopping experiences on their platforms for
								their customers.
							</p> */}
						</div>
						<div className='row mt-5 section-2-blocks'>
							<div className='col-4 p-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/PATENTED+TECHNOLOGY+(1).png'
									alt=''
									className='img-fluid  d-block mx-auto'
								/>
								<p className='text-center mt-2'>PATENTED TECHNOLOGY</p>
							</div>
							<div className='col-4 p-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/FEATURES+(1).png'
									alt=''
									className='img-fluid d-block mx-auto'
								/>
								<p className='text-center mt-2'>FEATURES</p>
							</div>
							<div className='col-4 p-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS.png'
									alt=''
									className='img-fluid  d-block mx-auto'
								/>
								<p className='text-center mt-2'>BENEFITS</p>
							</div>
						</div>
					</div>
					<div className='col-lg-6 home-section-2-img'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/website/saree+for+home+page+-+virtufit+solutions.png'
							alt=''
							className='img-fluid   d-block mx-auto'
						/>
					</div>
					<div className='col-lg-6 d-lg-none p-4'>
						<div className='mt-4 home-section-2-desc'>
							<p
								className=''
								style={{ fontSize: '0.99em' }}
							>
								Our Virtual try on called "Virtual Fitting Room" is a perfect
								solution to see yourself wearing that garment and create a
								beautiful experience for yourself and also see how it fits them.
								Moreover it is a patented technology which is scalable and
								affordable, making it possible for e commerce businesses to host
								state of the art shopping experiences on their platforms for
								their customers.
							</p>
						</div>
						<div className='row mt-5 section-2-blocks'>
							<div className='col-4 p-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/PATENTED+TECHNOLOGY+(1).png'
									alt=''
									className='img-fluid  d-block mx-auto'
								/>
								<p className='text-center mt-2'>PATENTED TECHNOLOGY</p>
							</div>
							<div className='col-4 p-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/FEATURES+(1).png'
									alt=''
									className='img-fluid d-block mx-auto'
								/>
								<p className='text-center mt-2'>FEATURES</p>
							</div>
							<div className='col-4 p-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS.png'
									alt=''
									className='img-fluid  d-block mx-auto'
								/>
								<p className='text-center mt-2'>BENEFITS</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ...................... */}
			<div className=' mt-5 mb-5'>
				<div className='position-relative heading-outer'>
					<div className='position-absolute  heading-line'></div>
					<div className='container heading-line-h1 '>
						<h1 className=' ms-3 ms-lg-0   ps-3 pe-3  '>
							OUR <br /> SOLUTIONS
						</h1>
					</div>
				</div>

				<div className='container mt-4'>
					<Swiper
						modules={[Navigation, Pagination, Scrollbar, A11y]}
						spaceBetween={5}
						slidesPerView={1.3}
						autoplay={true}
						navigation={true}
						breakpoints={{
							1024: {
								slidesPerView: 3,
								spaceBetween: 20,
							},
						}}
						slide
					>
						<SwiperSlide className='p-1'>
							<div className='position-relative hover-info-parent'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/slider-images/outfit+visualization.png'
									alt=''
									className='img-fluid'
								/>

								<div className='hover-info'>
									<p>
										Skip the hassle of trial rooms and try various fits and
										styles virtually to a quilt and seamless experience{' '}
									</p>
								</div>

								<div className='card-inner rounded-2'>
									<h3>Outfit Visualization</h3>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-1'>
							<div className='position-relative hover-info-parent overflow-hidden rounded-2'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/slider-images/Environment+Setting.png'
									alt=''
									className='img-fluid'
								/>
								<div className='hover-info'>
									<p>
										See how your fit interacts with different lighting and in
										different environments . You've chosen a cute outfit , leave
										it to us to make sure its the same in different
										environments.
									</p>
								</div>
								<div className='card-inner rounded-2'>
									<h3>Environment Setting </h3>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-1'>
							<div className='position-relative hover-info-parent'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/slider-images/Fit++Mapping.png'
									alt=''
									className='img-fluid'
								/>
								<div className='hover-info'>
									<p>
										Visualize how the apparel fits onto your body in different
										poses. Intuitive UI shows red as tight and green as loose
										fit and everything in-between.
									</p>
								</div>
								<div className='card-inner rounded-2'>
									<h3>Fit Mapping </h3>
								</div>
							</div>
						</SwiperSlide>

						<SwiperSlide className='p-1'>
							<div className='position-relative hover-info-parent'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/slider-images/Virtual++Twin.png'
									alt=''
									className='img-fluid'
								/>
								<div className='hover-info'>
									<p>
										Create a digital replica of yourself by uploading a selfie.
										Check out the apparel of your choice on your digital twin.
									</p>
								</div>
								<div className='card-inner rounded-2'>
									<h3>Virtual Twin </h3>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-1'>
							<div className='position-relative hover-info-parent'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/slider-images/hairstyle+switch.png'
									alt=''
									className='img-fluid'
								/>
								<div className='hover-info '>
									<p>
										Let your hair go wild in the wind or set them according to
										your look and fit . switch to various new hairstyles for
										visualization
									</p>
								</div>
								<div className='card-inner rounded-2'>
									<h3>Hairstyle Switch</h3>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-1'>
							<div className='position-relative hover-info-parent'>
								<img
									src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/accessory+trial+(1).png'
									alt=''
									className='img-fluid'
								/>
								<div className='hover-info '>
									<p>
										A look is incomplete without proper accessories. Select
										jewellery of your choice and pair it with heels of any colo
									</p>
								</div>
								<div className='card-inner rounded-2'>
									<h3>Accessory Trial</h3>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
			{/* ...................... */}
			<div className=' mt-5 mb-5'>
				<div className='position-relative heading-outer'>
					<div className='position-absolute  heading-line'></div>
					<div className='container heading-line-h1 '>
						<h1 className=' ms-3 ms-lg-0  ps-3 pe-3  '>
							HOW IT <br /> WORKS
						</h1>
					</div>
				</div>
				<div className='container section-4  d-none d-lg-block position-relative'>
					<div className='col-4 section-4-text-1   '>
						<p>
							We create a 3D virtual try-on experience using MyTwin avatars by
							3Dfy, seamlessly draping digital apparel and simulating real-time
							interaction.
						</p>
					</div>
					<div className='col-4 section-4-text-2 d-none d-lg-block   position-absolute'>
						<p>
							Your operator scans and uploads apparel via our web portal. 3Dfy
							is highly efficient, creating up to 20 3D saris or 100 3D T-shirts
							per hour. These digital garments are stored on the cloud for use
							by VirtuFit. If you use Shopify, InGnious AI offers an app
							integrating MyTwin and VirtuFit into your site. Apps for other
							platforms are available upon request.
						</p>
					</div>
					<div>
						<img
							src='./images/how-it-works-lg.gif'
							className='img-fluid '
							alt=''
									
						/>
					</div>
				</div>
				<div className='container d-lg-none'>
					<div className='p-2'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/How+it+works+01+1.gif'
							className='img-fluid  w-75 d-block mx-auto'
							alt=''
						/>
						<p>
							To enable a 3D virtual try-on experience, we create an avatar,
							made by MyTwin .A digital 3D apparel is also required , made by
							3Dfy, and a software which drapes the apparel on the avatar and
							simulates the interaction between avatar and apparel in Real Time.
						</p>
					</div>
				</div>
			</div>{' '}
			{/* .............................. */}
			<div className=' mt-5 mb-5'>
				<div className='position-relative heading-outer'>
					<div className='position-absolute  heading-line-left'></div>
					<div className='container heading-line-h1-left  text-end'>
						<h1 className='  me-auto  ps-3 pe-3  '>BENEFITS</h1>
					</div>
				</div>
				<div className='p-3 section-5'>
					<h3 className=' d-lg-none fw-bold'>FOR CONSUMERS</h3>
					<div className='row align-items-center'>
						<div className='col-lg-9'>
							<Swiper
								modules={[Navigation, Pagination, Scrollbar, A11y]}
								spaceBetween={5}
								slidesPerView={1.4}
								autoplay={true}
								breakpoints={{
									1024: {
										slidesPerView: 4,
										spaceBetween: 20,
									},
								}}
								slide
							>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Not+waiting+in+lines+for+trying+clothes+(1).png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2'>
											<b>Not waiting</b> in lines for trying clothes
										</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Offers+a+perfect+fit+with+accessories.png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2'>
											Offers a <b>perfect fit</b> with accessories
										</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Acts+as+a+personal+stylist+for+different+height+and+weight.png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2'>
											Acts as a <b>personal stylist</b>
										</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/See+the+ensemble+in+different+lights+according+to+your+skin+tones+(1).png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2'>
											See the ensemble in <b>different lights</b>
										</p>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
						<div className='col-lg-3 d-none d-lg-block'>
							<h3 className='text-center fw-bold'>FOR CONSUMERS</h3>
						</div>
					</div>
				</div>

				<div className='p-3 mt-5 section-5'>
					<h3 className=' d-lg-none text-end fw-bold'>FOR BUSINESSESS</h3>
					<div className='row align-items-center'>
						<div className='col-lg-3 d-none d-lg-block'>
							<h3 className='text-center fw-bold'>FOR BUSINESSES</h3>
						</div>
						<div className='col-lg-9'>
							<Swiper
								spaceBetween={5}
								modules={[Navigation, Pagination, Scrollbar, A11y]}
								slidesPerView={1.3}
								autoplay={true}
								breakpoints={{
									1024: {
										slidesPerView: 4,
										spaceBetween: 20,
									},
								}}
								slide
							>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Better+conversion+rate+and+more+volumes.png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2 fw-bold'>
											Better conversion rate and more volumes
										</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Reduced+returns+because+of+clear+decision+making.png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2 fw-bold'>
											Reduced returns because of clear decision making
										</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Improved+customer+satisfaction+and+brand+loyality.png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2 fw-bold'>
											Improved customer satisfaction and brand loyality
										</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className='p-1'>
									<div>
										<img
											src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/BENEFITS/Reduced+dead+stock+.+Better+projections+for+production.png'
											alt=''
											className='img-fluid w-50 d-block mx-auto'
										/>
										<p className='text-center mt-2 fw-bold'>
											Reduced dead stock Better projections for production
										</p>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
				</div>
			</div>
			<div
				className=' mt-5 pb-5 position-relative overflow-hidden'
				id='patented-tech'
			>
				<div className='position-relative heading-outer'>
					<div className='position-absolute  heading-line-left'></div>
					<div className='container heading-line-h1-left  '>
						<h1 className=' ms-3 ms-lg-0  ps-3 pe-3  '>
							PATENTED <br /> TECHNOLOGY
						</h1>
					</div>
				</div>

				<img
					src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/patented+tech+image.png'
					alt=''
					className='img-fluid'
					style={{
						position: 'absolute',
						width: '100%',
						opacity: '40%',
						bottom: '0%',
					}}
				/>

				<div className='container section-6'>
					<p className='mx-auto mt-lg-5'>
						Our patented proprietary scanner and state of the art AI algorithms
						creates 3D models of the clothes automatically and perfectly drapes
						it onto a given body of any dimension - making virtual trial rooms
						scalable and affordable. <br />
						<br /> One of our patented technologies simplifies the task of
						creating 3D models of clothes. All it needs is a power connection
						and an internet. Our innovative scanner and advanced AI algorithm
						automatically create precise 3D models of clothing, seamlessly
						draping them onto any body shape. This makes virtual fitting rooms
						scalable and cost-effective.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default HomeScreen
