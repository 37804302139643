import React from 'react'
import NavBar from '../Components/NavBar'
import { Icon } from '@iconify/react'
import '../Css/Fashion.css'
import Footer from '../Components/Footer'
const Fashion = () => {
	return (
		<div className='fashion-main'>
			<div className='position-relative  bg-light pb-lg-0'>
				<NavBar active={1} />
			</div>
			<div className='container fashion-section-1'>
				<div className='row align-items-lg-center'>
					<div className='col-lg-6 p-0 '>
						<div className='fashion-section-1-text h-100  pe-lg-5 me-lg-4 mb-lg-5'>
							<img
								src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/fashion/image+9.png'
								className='img-fluid d-lg-none'
								alt=''
							/>
							<h1>Virtual trial room -Revived retail experience </h1>
							<p className='mt-4 '>
								Exceptional customer service drives store loyalty. Digital
								innovations are reshaping retail, captivating consumers, and
								boosting sales as brands adopt virtual tools over traditional
								methods.
							</p>
							{/* <p className='mt-4 d-none d-lg-block'>
								New digital services are helping to power discovery, elicit awe
								from consumers and drive sales as retailers complete for
								attention. Progressively brands are leaning towards virtual
								tools instead of traditional consumption systems.
							</p> */}
							<p className='mt-4'>
								Our retail app “ Virtufit” offers virtual try-ons of apparel,
								providing a visual preview of how clothes look in different
								settings—an affordable, scalable solution for delivering
								cutting-edge shopping experiences.
							</p>
						</div>
					</div>
					<div className='col-6 p-0 d-none d-lg-block'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/image+9+(2).png'
							className='img-fluid d-block mx-auto'
							alt=''
						/>
					</div>
				</div>
				<div className=' d-lg-flex fashion-section-2   '>
					<div className='p-lg-5 mx-auto  d-lg-flex  fashion-section-2-bg   align-items-center'>
						<div className='col-3 d-none d-lg-block  '>
							<h3 className='text-center w-75'>
								Click a selfie and create a 3d virtual twin to try on clothes .{' '}
							</h3>
						</div>
						<div className='col-lg-6  '>
							<img
								src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/Upload-Instroction-ezgif+1+(1).gif'
								alt=''
								className='img-fluid w-100 rounded-3'
							/>
							<p
								className='text-center d-lg-none mt-2'
								style={{ color: '#FBA885' }}
							>
								Click a selfie and create a 3d virtual twin to try on clothes .
							</p>
						</div>
					</div>
				</div>
			</div>
			<div
				className='container mt-lg-5 fashion-section-3 '
				id='fashion-solution'
			>
				<div className='row align-items-center'>
					<div className='col-lg-6'>
						<h1>Personalized e-commerce shopping experience </h1>
						<p className='mt-4'>
							Excellent customer service boosts store loyalty by providing
							essential shopping information. Digital services drive discovery,
							awe consumers, and boost sales as brands favour virtual tools over
							traditional systems.
						</p>
						<p className='mt-4  d-lg-block'>
							Our e-commerce app “Virtufit” enables virtual apparel try-ons,
							offering affordable, scalable, and enjoyable shopping experiences.
						</p>
						{/* <p className='mt-4 d-none d-lg-block'>
							Our retail app lets you try on your favorite apparel virtually and
							get the visual idea of how an attire would look on you in
							different settings. Its scalable, affordable and fun solution for
							business or retails to host state of the art shopping experience
							fro the consumers{' '}
						</p> */}
					</div>
					<div className='col-lg-6'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/fashion/Group+15.png'
							alt=''
							className='img-fluid w-75 d-block mx-auto'
						/>
					</div>
				</div>
			</div>

			<div className='container mt-lg-0 fashion-section-4 '>
				<div className='row align-items-center'>
					<div className='col-lg-8 mb-0'>
						<h1 className='d-lg-none'>Fit Mapping </h1>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/fashion/Group+21+copy.png'
							alt=''
							className='img-fluid  d-block mx-auto'
						/>
					</div>
					<div className='col-lg-4'>
						<h1 className='d-none d-lg-block'>Fit Mapping </h1>
						<p className='mt-4'>
							Our technology digitally drapes garments on a customized 3D
							replica, showing multiple sizes and fabrics for a realistic fit
							preview. Our size and fit service on product pages eliminates
							guesswork, providing precise recommendations based on unique
							measurements for successful online shopping.
						</p>
					</div>
				</div>
			</div>
			<div className='container fashion-section-5'>
				<h1>Cataloging solutions </h1>
				<div className='row  align-items-center'>
					<div className='col-lg-4'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/Group+17+(1).png'
							alt=''
							className='img-fluid'
						/>
						<button className=''>GENERATE SHOOT IMAGES</button>
					</div>
					<div className='col-lg-8  mt-3 mt-lg-0'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/fashion/catalog+1+(1).png'
							className='img-fluid catalog-img '
							alt=''
						/>
					</div>
					<div className='col-lg-10 pb-5 mt-4 offset-lg-1'>
						<p className=''>
							Visuals are crucial in E-commerce success. According to Shopify,
							product photos outweigh information, reviews, and ratings for over
							half of online shoppers.
						</p>{' '}
						<p className=''>
							Traditional photo shoots are expensive, time-consuming, and
							labour- intensive. Our solution delivers high-quality photos
							quickly and affordably, transforming the process
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Fashion
