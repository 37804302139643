import React from 'react'
import NavBar from '../Components/NavBar'
import { Icon } from '@iconify/react'
import SwiperCore, { Pagination, Autoplay, EffectCoverflow } from 'swiper'
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import '../Css/Services.css'
import Footer from '../Components/Footer'
const Services = () => {
	return (
		<div>
			<div>
				<NavBar active={2} />
			</div>
			<div className='container  mt-lg-5'>
				<div className='row align-items-lg-center '>
					<div className='col-lg-6 '>
						<img
							className='img-fluid w-100 '
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/image+15+(1).png'
							alt=''
						/>
					</div>
					<div className='col-lg-6 services-section-1 p-3  ps-lg-5 mb-lg-5  '>
						<h1 className='    ps-3 pe-3 text-center '>
							AI Automated visual inspection
						</h1>
						<p className=''>
							By using computer algorithms to assess images for quality,
							companies automate visual inspections, saving time and enhancing
							accuracy. This technology, also known as intelligent visual
							inspections, enables faster, more precise, and cost-effective
							assessments in diverse settings. It also ensures safety by
							deploying machines in hazardous areas, protecting workers while
							maintaining inspection benefits.
						</p>
					</div>
				</div>
				<div className='mt-lg-5 mt-3 services-section-2 mb-lg-5 mb-3'>
					<Swiper
						spaceBetween={5}
						slidesPerView={2}
						autoplay={true}
						autoHeight={true}
						breakpoints={{
							1024: {
								slidesPerView: 4,
								spaceBetween: 20,
							},
						}}
						slide
					>
						<SwiperSlide className='p-3 d-flex align-items-center h-100 border-2 border-dark border-start '>
							<div className='  '>
								<h4 className='text-center'>Visual inspection of paint </h4>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-3 d-flex align-items-center  h-100 border-2 border-dark border-start '>
							<div className='  '>
								<h4 className='text-center'>
									Inspection of Automotive Seating
								</h4>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-3 d-flex align-items-center  h-100 border-2 border-dark border-start '>
							<div className='  '>
								<h4 className='text-center'>
									Visual Inspection of Shock Absorber Piston{' '}
								</h4>
							</div>
						</SwiperSlide>
						<SwiperSlide className='p-3 d-flex align-items-center  h-100 border-2 border-dark border-start '>
							<div className='  '>
								<h4 className='text-center'>
									{' '}
									Visual Inspection of Motor Armature
								</h4>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>

				<div
					className='position-relative services-section-3 '
					id='ig-services-solution'
				>
					<h1 className=' position-absolute '>AI Visual inspection of paint</h1>
					<div className='row  align-items-center '>
						<div className='col-lg-8'>
							<img
								src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/Group+7.png'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='col-lg-4 mt-3 ps-3 ps-lg-0 mt-lg-0'>
							<p>
								Our AI system that checks the quality of the paint on panels.
								This system is configured to detect defects such as moisture
								blisters, bubbling, blowing, paint runs, dirt inclusions etc.
							</p>
						</div>
					</div>
				</div>

				<div className='row position-relative section-section-4 align-items-center'>
					<div className='col-lg-8 p-3  p-lg-3'>
						<h1 className=' position-relative '>
							AI Inspection of Automotive Seating
						</h1>
						<p>
							{' '}
							We are currently working on automation of visual inspection of car
							seat quality. It uses multiple cameras to capture images from
							multiple angles and uses AI to identify defects such as missing
							parts, wrinkles, cuts, wrong stitching etc. An end-to-end solution
							is being provided where we provide the hardware, software and the
							mounting and integration setup
						</p>

						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/Group+20+1.png'
							alt=''
							className='img-fluid  w-100'
						/>
					</div>
					<div className='col-lg-4 d-none p-lg-3 d-lg-block  position-relative'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/image+24+(1).png'
							alt=''
							className='img-fluid w-100 h-100'
						/>
					</div>
				</div>

				<div className='row align-items-center services-section-5'>
					<h1 className=' text-center d-lg-none'>
						AI Automated Visual Inspection of Motor Armature
					</h1>
					<div className='col-lg-5'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/new-images/9bb754b1f7cc10f8b22240ede765d01d-ezgif+2+(1).png'
							alt=''
							className='img-fluid'
						/>
					</div>
					<div className='col-lg-7 '>
						<h1 className=' text-center d-lg-block d-none'>
							AI Automated Visual Inspection of Motor Armature
						</h1>
						<p className='mx-auto section-4-p mt-4'>
							An embedded system detects various defects on different parts of
							motor armature. Multiple cameras are attached to the process to
							provide real time information.
						</p>
					</div>
				</div>
				<div className='row align-items-center mt-lg-5 services-section-5'>
					<div className='col-lg-7'>
						<h1 className=' text-center'>
							AI Automated Alignment Inspection of Shock Absorber Piston
						</h1>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/9bb754b1f7cc10f8b22240ede765d01d-ezgif+3+(1).png'
							alt=''
							className='img-fluid rounded-5 d-lg-none d-block'
						/>
						<p className=' mt-lg-5 section-5-p mt-3 ps-2'>
							An embedded system that verifies that threading is done correctly
							and holes are drilled as per specifications. The device houses a
							powerful single board computer that runs our AI to detect defects.
							2 cameras are attached to the process to provide real time
							information.
						</p>
					</div>
					<div className='col-lg-5 d-none d-lg-block'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/9bb754b1f7cc10f8b22240ede765d01d-ezgif+3+(1).png'
							alt=''
							className='img-fluid rounded-5'
						/>
					</div>
				</div>
			</div>
			<div className=' mt-5 mb-lg-5'>
				<div className='position-relative '>
					<div
						className='position-absolute '
						style={{
							background: '#FBA885',
							height: '2px',
							width: '95%',
							zIndex: '1',
							top: '40%',
							left: '50%',
							translate: '-50% -40%',
						}}
					></div>
					<div className='container text-end '>
						<h1
							className=' d-inline-block me-3   ps-3 pe-3 position-relative '
							style={{
								color: '#FBA885',
								fontWeight: '700',
								fontSize: '2em',
								zIndex: '5',
								backgroundColor: '#fff',
							}}
						>
							BENEFITS
						</h1>
					</div>
				</div>
			</div>
			<div
				className='p-3'
				style={{
					background: 'rgb(246,198,193)',
					background:
						'linear-gradient(90deg, rgba(246,198,193,1) 0%, rgba(248,207,198,1) 44%, rgba(246,246,248,1) 91%)',
				}}
			>
				<div className='container'>
					<div className='col-12'>
						<Swiper
							spaceBetween={5}
							slidesPerView={1.3}
							autoplay={true}
							breakpoints={{
								1024: {
									slidesPerView: 4,
									spaceBetween: 20,
								},
							}}
							slide
						>
							<SwiperSlide className='p-1'>
								<div>
									<img
										src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/Economic+and+time+efficiency.png'
										alt=''
										className='img-fluid w-50 d-block mx-auto'
									/>
									<p className='text-center mt-2 mb-0 fw-bold'>
										Economic and time efficiency
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide className='p-1'>
								<div>
									<img
										src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/Optimization+of+quality+assurance.png'
										alt=''
										className='img-fluid w-50 d-block mx-auto'
									/>
									<p className='text-center mt-2 mb-0 fw-bold'>
										Optimization of quality assurance
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide className='p-1'>
								<div>
									<img
										src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/Reduction+of+human+error.png'
										alt=''
										className='img-fluid w-50 d-block mx-auto'
									/>
									<p className='text-center mt-2 mb-0 fw-bold'>
										Reduction of human error
									</p>
								</div>
							</SwiperSlide>
							<SwiperSlide className='p-1'>
								<div>
									<img
										src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/Increased+safety+in+workplace.png'
										alt=''
										className='img-fluid w-50 d-block mx-auto'
									/>
									<p className='text-center mt-2 mb-0 fw-bold'>
										Increased safety in workplace
									</p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
			</div>
			<div className='container services-section-6 mb-4'>
				<div>
					<h1
						className='   text-center position-relative '
						style={{}}
					>
						Assistance for Higher Digital <br className='d-lg-block d-none' />{' '}
						Adaption
					</h1>
				</div>
				<p className='mt-lg-5'>
					AI enhances digital assistants by offering personalized responses and
					remembering past interactions for relevance. Context-aware assistants
					identify user location, platform, or context to deliver pertinent
					information. By automating tasks, these assistants increase
					productivity and efficiency, helping users accomplish more in less
					time.
				</p>
				<div className='row align-items-center services-section-7 pb-5'>
					<div className='col-lg-6'>
						<img
							src='https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/services+page/image+26.png'
							alt=''
							className='img-fluid'
						/>
					</div>
					<div className='col-lg-6 p-lg-4 mt-5 mt-lg-0'>
						<h1 className='   text-center position-relative '>
							Automated Chassis Number Identification for Rapid Registration
						</h1>
						<p className='mt-lg-4'>
							In used tractor market, a user needs to upload a lot of
							information online which leads to high dropout. To resolve this, a
							cloud-based solution that identifies the chassis number from the
							user uploaded photograph and uses this to query e-vahan database
							to get information such as make, model, manufacturing date etc.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Services
